import React, { useEffect, useState } from "react";
import "./App.css";
import i18n from "i18next";
import { initReactI18next, Trans, withTranslation } from "react-i18next";
import { PrivacyNotificationDialog, SubmitDialog } from "./components/dialog";
import { useDispatch, useSelector } from "react-redux";
import { elementAction, feedbackAction } from "./store";
import Backend from "i18next-http-backend";
import Uwp, { UploadFileStatusEnum } from "./utils/uwp";
import Log from "./utils/log";
import { AppClient } from "./utils/tool/client";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Home from "./Home";
import LikeConfirm from "./LikeConfirm";

let lng = "en";

i18n
  .use(Backend)
  // .use(LanguageDetector) //偵測瀏覽器語言
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    backend: {
      loadPath: process.env.PUBLIC_URL + "/locales/{{lng}}/{{ns}}.json",
    },
    lng, // disabled detection
    fallbackLng: {
      default: ["en"],
      "zh-hant": ["zh-TW"],
      "zh-hans": ["zh-CN"],
    },
    react: {
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ["br", "strong", "b", "i"],
      wait: true,
    },
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  })
  .then((r) => {});

function App({ t, i18n }) {
  const dispatch = useDispatch();
  const [selectedLikeType, setSelectedLikeType] = useState("");
  const privacyNotificationDialogId = useSelector(
    (state) => state.element.privacyNotificationDialogId
  );
  const submitDialogId = useSelector((state) => state.element.submitDialogId);
  const errorDialogId = useSelector((state) => state.element.errorDialogId);
  const lang = useSelector((state) => state.element.lang);
  const isShowErrorDialog = useSelector(
    (state) => state.element.isShowErrorDialog
  );
  const isShowPrivacyNotificationDialog = useSelector(
    (state) => state.element.isShowPrivacyNotificationDialog
  );
  const isShowSubmitDialog = useSelector(
    (state) => state.element.isShowSubmitDialog
  );
  const attachmentFileUploadDone = useSelector(
    (state) => state.feedback.attachmentFileUploadDone
  );
  const uploadStatus = useSelector((state) => state.uwp.uploadStatus);
  const uploadProgress = useSelector((state) => state.uwp.uploadProgress);
  const [isSetLanguage, setIsSetLanguage] = React.useState(false);
  const focusNameList = useSelector((state) => state.element.focusNameList);
  const theme = useSelector((state) => state.element.theme);
  const [loadingImgType, setLoadingImgType] = useState("dark");

  const getTheme = async () => {
    if (Uwp.getBridge()) {
      Log.debug("[UWP] start get theme ");
      const themeType = await Uwp.getThemeType();
      if (themeType) {
        const t = themeType.toLowerCase();
        Log.debug("[UWP] start get theme ", t);
        dispatch(elementAction.setTheme(t));
        if (t === "light") {
          setLoadingImgType("light");
        }
      }
    } else {
      // dispatch(elementAction.setTheme("light"));

      Log.debug("[UWP] no UWP, skip get theme ");
    }
  };

  const getCurrentLanguage = async () => {
    let lang = navigator.language || navigator.userLanguage;
    if (Uwp.getBridge()) {
      lang = await Uwp.getCurrentLanguage();
      Log.info("use UWP language:", lang);
    } else {
      Log.info("use browser language:", lang);
    }
    dispatch(elementAction.setLang(lang));

    setTimeout(() => {
      setIsSetLanguage(true);
      dispatch(elementAction.setLang(lang));
    }, 1000);
  };
  const getCountryCode = async () => {
    const countryCode = await AppClient.getCountry();
    dispatch(feedbackAction.setGeoCountry(countryCode));
  };

  const getUserInfo = async () => {
    if (Uwp.getBridge()) {
      Log.debug("[UWP] start get user info ");
      const userInfo = await Uwp.getLoginUserInfo();
      if (userInfo) {
        Log.debug("[UWP] start get user info ", userInfo);
        dispatch(feedbackAction.setEmail(userInfo.Email));
      }
    } else {
      Log.debug("[UWP] no UWP, skip get user info ");
    }
  };

  useEffect(() => {
    getTheme();
    getUserInfo();
    getCurrentLanguage();
    getCountryCode();
  }, []);

  useEffect(() => {
    Log.debug("[UWP] change upload status ", uploadStatus);
    if (uploadStatus === UploadFileStatusEnum.UploadFileFinish) {
      dispatch(feedbackAction.submitSuccess());
    }
    if (uploadStatus > UploadFileStatusEnum.PackageEnd) {
      if (uploadStatus % 100 === 0 || uploadStatus % 100 === 2) {
        dispatch(
          feedbackAction.setLogUploadProgress(80 + Number(uploadProgress) * 0.2)
        );
      }
      if (Number(uploadStatus) % 100 === 4) {
        Log.error("[UWP] upload fail, dispatch submitFailed", uploadStatus);
        dispatch(feedbackAction.submitFailed());
      }
    }
  }, [uploadStatus]);

  useEffect(() => {
    if (selectedLikeType === "") {
      Uwp.clearWebContent();
    } else {
      Uwp.changeWebContent();
    }
  }, [selectedLikeType]);

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang]);

  useEffect(() => {
    triggerDialog(errorDialogId, isShowErrorDialog);
  }, [errorDialogId, isShowErrorDialog]);

  useEffect(() => {
    triggerDialog(privacyNotificationDialogId, isShowPrivacyNotificationDialog);
    dispatch(
      elementAction.setEnableRootKeyboardEvent(!isShowPrivacyNotificationDialog)
    );
  }, [privacyNotificationDialogId, isShowPrivacyNotificationDialog]);

  useEffect(() => {
    triggerDialog(submitDialogId, isShowSubmitDialog);
    dispatch(elementAction.setEnableRootKeyboardEvent(!isShowSubmitDialog));
  }, [submitDialogId, isShowSubmitDialog]);

  useEffect(() => {
    if (attachmentFileUploadDone) {
      dispatch(feedbackAction.submitSuccess());
    }
  }, [attachmentFileUploadDone]);

  function triggerDialog(name, action) {
    const target = document.getElementById(name);
    if (target) {
      action ? target.showModal() : target.close();
    }
  }
  return (
    <Router>
      <Routes>
        <Route
          path="/ac-feedback-hub"
          element={<Home t={t} i18n={i18n} isSetLanguage={isSetLanguage} />}
        />
        <Route
          path="/ac-feedback-hub/like-confirm"
          element={<LikeConfirm t={t} i18n={i18n} />}
        />
      </Routes>
    </Router>
  );
}

export default withTranslation()(App);
