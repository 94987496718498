import React, { useEffect, useState } from "react";
import { Select, Input, Checkbox, TextArea, Button } from "./elements";
import { ValidateEmail } from "../utils/format";
import { useDispatch, useSelector } from "react-redux";
import { elementAction, feedbackAction, uwpAction } from "../store";
import Uwp, { UploadFileStatusEnum } from "../utils/uwp";
import Api, { CreateAC } from "../api";
import Log from "../utils/log";
import { Trans } from "react-i18next";
import TitleIcon from "./elements/TitleIcon";
import Uploader from "./elements/Uploader";
import { FeedbackClassification } from "../utils/constatnts";

export default function ArmouryCrateReportForm(props) {
  const { t } = props;

  const categoryOptions = [
    {
      value: "#home",
      subOptions: ["#50000", "#50001", "#50002", "#50003", "#50004"],
    },
    {
      value: "#devices",
      subOptions: ["#50005"],
    },
    {
      value: "#aura_sync",
      subOptions: ["#50006", "#50007"],
    },
    {
      value: "#scenario_profiles",
      subOptions: ["#50008"],
    },
    {
      value: "#macros",
      subOptions: ["#500009"],
    },
    {
      value: "#game_library",
      subOptions: ["#50010"],
    },
    {
      value: "#game_visual",
      subOptions: ["#50011"],
    },
    {
      value: "#function_library",
      subOptions: ["#50012"],
    },
    {
      value: "#user_center",
      subOptions: ["#50013"],
    },
    {
      value: "#settings",
      subOptions: ["#50014", "#50015"],
    },
    {
      value: "#other",
      subOptions: ["#500016"],
    },
  ];
  const theme = useSelector((state) => state.element.theme);

  const categoryI18n = t("improve_options", { returnObjects: true });
  const email = useSelector((state) => state.feedback.email);
  const [enabledShowFirstCategoryOption, setEnabledShowFirstCategoryOption] =
    useState(false);
  const [enabledShowSecondCategoryOption, setEnabledShowSecondCategoryOption] =
    useState(false);

  const [firstCategory, setFirstCategory] = useState("");
  const [secondCategoryOptions, setSecondCategoryOptions] = useState([]);

  const [firstCategoryFocus, setFirstCategoryFocus] = useState(false);
  const [secondCategoryFocus, setSecondCategoryFocus] = useState(false);

  const [showSecondCategory, setShowSecondCategory] = useState(false);
  const privacyNotificationDialogId = useSelector(
    (state) => state.element.privacyNotificationDialogId
  );

  const form = useSelector((state) => state.feedback);
  const enabledSubmit = useSelector((state) => state.feedback.enableSubmit);
  const isFocus = useSelector((state) => state.element.isFocus);

  const [enabledPrivacyPolicy, setEnabledPrivacyPolicy] = useState(false);
  const [submitLock, setSubmitLock] = useState(false);
  const api = new Api({
    // eslint-disable-next-line no-undef
    url: config.BACKEND_URL,
    token: "db18b1b2-d53a-4ab8-9725-c77246b65c0e",
  });

  /**
   *  Keyboard event used to control focus
   */
  const focusIndex = useSelector((state) => state.element.focusIndex);
  const focusNameList = useSelector((state) => state.element.focusNameList);

  const dispatch = useDispatch();
  const { setCategory, setEmail, checkSubmit, setDescription } = feedbackAction;

  useEffect(() => {
    if (!enabledSubmit) {
      setFirstCategory("");
      dispatch(setCategory(""));
      setShowSecondCategory(false);
    }
  }, [enabledSubmit]);

  useEffect(() => {
    if (email !== "") {
      if (ValidateEmail(email)) {
        setEnabledPrivacyPolicy(true);
      } else {
        setEnabledPrivacyPolicy(false);
      }
      return;
    }
    setEnabledPrivacyPolicy(false);
  }, [email]);

  function escEvent(event) {
    if (event.key === "Escape") {
      setEnabledShowFirstCategoryOption(false);
      setEnabledShowSecondCategoryOption(false);
    }
  }

  useEffect(() => {
    document.addEventListener("keydown", escEvent, false);
  });

  const changeDescription = (event) => {
    dispatch(setDescription(event.target.value));
    dispatch(checkSubmit());
  };

  function changeCategory(value) {
    dispatch(setCategory(value));
    setFirstCategoryFocus(false);
    setSecondCategoryFocus(false);
    setEnabledShowFirstCategoryOption(false);
    setEnabledShowSecondCategoryOption(false);
    dispatch(checkSubmit());
  }

  function changeFirstCategory(value) {
    setFirstCategory(value);
    const secondOptions = categoryOptions.find((item) => {
      return item.value === value;
    }).subOptions;
    setSecondCategoryOptions(secondOptions);
    if (
      [
        "#devices",
        "#scenario_profiles",
        "#macros",
        "#game_library",
        "#game_visual",
        "#function_library",
        "#user_center",
        "#other",
      ].find((item) => {
        return item === value;
      })
    ) {
      setShowSecondCategory(false);
    } else {
      setShowSecondCategory(true);
    }
    changeCategory(secondOptions[0]);
  }

  function changeSecondCategory(value) {
    changeCategory(value);
  }

  const inputEmail = (val) => {
    dispatch(setEmail(val));
    dispatch(checkSubmit());
  };

  function clickPrivacyNotice() {
    if (!enabledPrivacyPolicy) return;
    dispatch(
      elementAction.triggerDialog({
        id: privacyNotificationDialogId,
        isOpen: true,
      })
    );
  }

  useEffect(() => {
    dispatch(elementAction.setEnableRootKeyboardEvent(!submitLock));
  }, [submitLock]);

  async function _submit(event) {
    event.preventDefault();
    if (submitLock) return;
    setSubmitLock(true);
    dispatch(elementAction.triggerSubmitDialog(true));

    const feedbackInfo = await Uwp.getFeedbackInfo(3);
    const privacy_agreed_at =
      window.$react.store.getState().feedback.privacyNoticeAgreedAt;
    if (!Uwp.getBridge()) {
      dispatch(feedbackAction.submitFailed());
      Log.error("UWP Bridge is not ready");
    } else {
      mockProcess({
        percentage: 80,
        speed: 500,
        step: 2,
      });
      try {
        const resp = await api.POST(CreateAC, {
          ...{
            ...feedbackInfo,
            email: form.email,
            feedback_classification: FeedbackClassification.Bad,
            category_id: form.category_id,
            description: form.description,
            privacy_agreed_at,
          },
        });
        const { status, data } = resp;
        if (status === 200) {
          const { id } = data;
          try {
            const acceptUpload = await Uwp.uploadLogFile(id);
            await syncUwpStatus();
            if (acceptUpload) {
              dispatch(uwpAction.enableSync());
            } else {
              dispatch(feedbackAction.submitFailed());
              Log.error("UWP uploadLogFile return false");
            }
          } catch (e) {
            dispatch(feedbackAction.submitFailed());
          }
        } else {
          dispatch(feedbackAction.submitFailed());
          Log.error("CreateAC API response status is not 200");
        }
      } catch (e) {
        dispatch(feedbackAction.submitFailed());
        Log.error("CreateAC API response status is not 200", e);
      }
    }

    dispatch(elementAction.triggerSubmitDialog(true));
    setTimeout(() => {
      setSubmitLock(false);
    }, 1000);
  }

  async function syncUwpStatus() {
    const _uploadFileStatus = await Uwp.getUploadLogFileStatus();
    // console.log(_uploadFileStatus);
    dispatch(uwpAction.updateUploadStatus(_uploadFileStatus));
  }

  async function mockProcess({ percentage, speed, step = 1 }) {
    let count = 0;
    let timerId = null;
    timerId = setInterval(async function timer() {
      const _uploadFileStatus = await Uwp.getUploadLogFileStatus();
      if (_uploadFileStatus.status < UploadFileStatusEnum.PackageEnd) {
        dispatch(feedbackAction.setLogUploadProgress(count)); //  if the counter < 10, call the loop function
        count += step;
        if (count > percentage) {
          clearInterval(timerId);
        }
      } else {
        clearInterval(timerId);
      }
    }, speed);
  }

  const emailCheck = () => {
    if (form.email === "") return true;
    return form.email !== "" && ValidateEmail(form.email);
  };

  const selectedBtn = (name) => {
    // return isSelectedItem(name) ? ` border-${theme}-on-focus ` : `  `
    return isSelectedItem(name)
      ? ` border-${theme}-on-focus border-[5px] opacity-80 placeholder-${theme}-focus bg-${theme}-btn-enabled `
      : ` bg-${theme}-btn-disabled border-[0px]  placeholder-${theme}-normal hover:border-[3px]`;
  };

  const isSelectedItem = (name) => {
    const idx = focusNameList.findIndex((item) => {
      return item === name;
    });
    return idx === focusIndex && isFocus;
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
      }}
      autoComplete="off"
      className={"relative"}
    >
      <FocusNameListController
        enabledPrivacyPolicy={enabledPrivacyPolicy}
        showSecondCategory={showSecondCategory}
        enabledSubmit={enabledSubmit}
      />
      {/*<div className={'mt-12 text-white flex items-baseline space-x-3'}>*/}
      {/*  <TitleIcon width={40} height={15}/>*/}
      {/*  <p className={`title text-${theme}-normal`}>{t(*/}
      {/*    'dislike_form_title')}</p>*/}
      {/*</div>*/}

      {/* Input First Category Start*/}
      <div className={"mt-5 text-xl space-y-2"}>
        <p className={`text-${theme}-normal`}>{t("main_category")}</p>
        <Select
          t={t}
          options_i18n={"improve_options"}
          name={"first_category"}
          value={firstCategory}
          placeHolder={t("please_select_main_category")}
          options={categoryOptions}
          changeValue={(val) => {
            changeFirstCategory(val);
          }}
        />
      </div>
      {/* Input First Category End*/}
      {/* Input Second Category Start*/}
      {showSecondCategory && (
        <div className={"mt-5 text-xl space-y-2"}>
          <p className={`text-${theme}-normal`}>{t("secondary_category")}</p>
          <Select
            t={t}
            options_i18n={"improve_options"}
            name={"second_category"}
            value={form.category_id}
            placeHolder={t("please_select_secondary_category")}
            options={secondCategoryOptions}
            changeValue={(val) => {
              changeSecondCategory(val);
            }}
          />
        </div>
      )}
      {/* Input Second Category End*/}
      <div className="mt-5">
        <p className={`text-xl text-${theme}-normal break-all`}>
          <Trans i18nKey="subTitle">
            <a className="underline" href={t("acse_faq_link")} target={"new"} />
            <a
              className="underline"
              href={t("asus_service_center_url")}
              target={"new"}
            />
          </Trans>
        </p>
      </div>
      {/* Input Description Start*/}
      <TextArea
        className={
          "mt-2 flex flex-col relative " +
          (showSecondCategory ? "h-[150px]" : "h-[208px]")
        }
        id="description"
        name="description"
        isFocus={isSelectedItem}
        onChange={changeDescription}
        form={form}
        t={t}
        theme={theme}
      />
      {/* Upload Screenshot Start*/}
      <div className="mt-5 text-xl space-y-2">
        <Uploader t={t} />
      </div>
      {/* Upload Screenshot End*/}

      {/* Input Email Start*/}
      <Input
        id={"email"}
        type={"email"}
        placeHolder={t("email")}
        errorText={t("email_error")}
        value={form.email}
        onChange={inputEmail}
        formatCheck={emailCheck}
      />
      {/* Input Email End*/}
      {/* Last Column Start */}
      <div className="mt-10 flex flex-col space-y-2">
        {/*Privacy Notice Start */}
        <Checkbox
          id={"privacy_notification"}
          label={t("privacyNotice")}
          className={"flex max-w-full mx-5"}
          theme={theme}
          isFocus={isSelectedItem("privacy_notification")}
          isEnabled={enabledPrivacyPolicy}
          isChecked={form.agreePrivacyPolicy}
          onClick={clickPrivacyNotice}
        />
        {/* <div className="flex justify-start iteFFFFFFms-center w-2/3">
           <div
            id='privacy_notification'
            aria-hidden={"true"}
            aria-label={t('privacyNotice')}
            aria-roledescription={'checkbox'}
            className={`w-7 h-7 mr-3 border-[2px]
            ${checkboxItem('privacy_notification')}`}
            onClick={clickPrivacyNotice}
          />
          <label className={`text underline ${enabledPrivacyPolicy
            ? `text-${theme}-normal`
            : `text-${theme}-disabled`}`} form="scales"
           aria-label={t('privacyNotice')}
           aria-roledescription={'input'}
           aria-hidden={"true"}
           onClick={clickPrivacyNotice}>
            {t('privacyNotice')}</label>
        </div> */}
        {/*Privacy Notice End */}
        {/*Submit Button Start */}
        <div className="group flex justify-end h-[40px] ">
          <Button
            id="submit"
            theme={theme}
            onClick={_submit}
            isFocus={isSelectedItem}
            isEnabled={enabledSubmit}
          >
            {t("submit")}
          </Button>
          {/* <button
            id="submit"
            disabled={!enabledSubmit}
            type="button"
            onClick={(e) => _submit(e)}
            className={`h-full max-w-[50vh] min-w-[25vh] button-text px-4
            ${
              enabledSubmit
                ? selectedBtn("submit") +
                  ` text-${theme}-normal group-hover:bg-${theme}-btn-hover group-hover:border-${theme}-btn-hover  group-active:bg-${theme}-btn-click group-active:border-${theme}-btn-click `
                : ` text-${theme}-disabled bg-${theme}-btn-disabled`
            }
             font-semibold relative`}
          >
            {t("submit")}
          </button> */}
        </div>
        {/*Submit Button End */}
      </div>
      {/* Last Column End */}
    </form>
  );
}

function FocusNameListController({
  showSecondCategory,
  enabledPrivacyPolicy,
  enabledSubmit,
}) {
  const dispatch = useDispatch();

  useEffect(() => {
    const list = ["email", "first_category"];
    if (showSecondCategory) list.push("second_category");
    list.push("description");
    if (enabledPrivacyPolicy) list.push("privacy_notification");
    if (enabledSubmit) list.push("submit");
    dispatch(elementAction.setFocusNameList(list));
  }, [showSecondCategory, enabledPrivacyPolicy, enabledSubmit]);

  return <></>;
}
