import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Checkbox, TextArea } from "./elements";
import TitleIcon from "./elements/TitleIcon";
import { elementAction, feedbackAction, uwpAction } from "../store";
import Api, { CreateAC } from "../api";
import { FeedbackClassification } from "../utils/constatnts";
import Uwp from "../utils/uwp";
import Log from "../utils/log";

export default function Form(props) {
  const { t } = props;
  const theme = useSelector((state) => state.element.theme);
  const form = useSelector((state) => state.feedback);
  const likeLabelI18n = t("like_options", { returnObjects: true });
  const dispatch = useDispatch();
  const [selectedOptions, setSelectedOptions] = React.useState([]);
  const api = new Api({
    // eslint-disable-next-line no-undef
    url: config.BACKEND_URL,
    token: "db18b1b2-d53a-4ab8-9725-c77246b65c0e",
  });
  const likeOptions = useMemo(() => {
    const options = [];
    for (let i = 1; i <= 7; i++) {
      options.push(`#${i}`);
    }
    return options;
  }, []);

  useEffect(() => {
    dispatch(feedbackAction.setCategories(selectedOptions));
  }, [selectedOptions]);

  function pickOption(option) {
    if (selectedOptions.includes(option)) {
      setSelectedOptions(selectedOptions.filter((item) => item !== option));
    } else {
      setSelectedOptions([...selectedOptions, option]);
    }
  }

  function isOptionSelected(option) {
    return selectedOptions.includes(option);
  }

  async function submit() {
    try {
      const resp = await api.POST(CreateAC, {
        ...{
          feedback_classification: FeedbackClassification.Good,
          categories: selectedOptions,
          description: form.description,
        },
      });
      const { status, data } = resp;
      if (status === 200) {
        const { id } = data;
        try {
          dispatch(elementAction.triggerSubmitDialog(true));
          dispatch(feedbackAction.submitLike());
        } catch (e) {
          dispatch(elementAction.triggerSubmitDialog(true));
          dispatch(feedbackAction.submitFailed());
        }
      } else {
        dispatch(elementAction.triggerSubmitDialog(true));
        dispatch(feedbackAction.submitFailed());
        Log.error("CreateAC API response status is not 200");
      }
    } catch (e) {
      dispatch(elementAction.triggerSubmitDialog(true));
      dispatch(feedbackAction.submitFailed());
      Log.error("CreateAC API response status is not 200");
    }
  }
  const changeDescription = (event) => {
    dispatch(feedbackAction.setDescription(event.target.value));
  };

  return (
    <>
      <div className={"mt-12 text-white flex items-baseline space-x-3"}>
        <TitleIcon width={40} height={15} />
        <p className={`title text-${theme}-normal`}>{t("like_form_title")}</p>
      </div>
      <div className={"flex flex-wrap my-4 gap-7"}>
        {likeOptions.map((option, index) => {
          return (
            <Checkbox
              key={index}
              id={option}
              label={likeLabelI18n[option]}
              className={"justify-start items-center max-w-full"}
              theme={theme}
              isFocus={false}
              isEnabled={true}
              isChecked={isOptionSelected(option)}
              onClick={() => {
                pickOption(option);
              }}
            />
          );
        })}
      </div>
      <div className={"h-[150px]"}>
        <TextArea
          className={"flex flex-col mt-5 relative h-full"}
          id="like_description"
          name="description"
          isFocus={(name) => {
            return false;
          }}
          onChange={changeDescription}
          form={form}
          t={t}
          theme={theme}
        />
      </div>
      <div className="group flex justify-end items-center w-full mt-5 h-[52px] relative ">
        <Button
          id="submit"
          theme={theme}
          onClick={submit}
          isFocus={(name) => false}
          isEnabled={selectedOptions.length > 0}
        >
          {t("submit")}
        </Button>
      </div>
    </>
  );
}
